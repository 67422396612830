
.row-content_repeater{
	.content-repeater_item {
		position: relative;
		.entry-header{
			font-size: 17px;
			font-weight: 500;
			margin-bottom: 6px;
		}
		&:not(:last-child){
			padding-bottom: 32px;
			margin-bottom: 32px;
			&:before{
				content: '';
				position: absolute;
				bottom: 0; left: 50%;
				transform: translateX(-50%);
				width: 82px; height: 2px;
				background: $primary;
			}
		}

		@include media-breakpoint-up(xl){
			padding-left: 8.33333%;
			padding-right: 8.33333%;
		}
	}
	.additional__footer{
		.buttons-wrap{
			a{
				position: relative;
				padding-right: 20px;
				&:after{
					content: '\f107';
					font-family: fontawesome;
				    position: absolute;
				    top: 0;
				    right: 0;
				}
			}
		}
	}
}