.row-featured_image{
	img.image{
		width: 100%;
	}
	.caption-wrap{
	    position: absolute;
	    top: 0;
	    left: 0;
	    right: 0;
	    bottom: 0;
	    .caption{
	        position: sticky;
		    position: -webkit-sticky;
		    top: 0;		    
		    padding-top: 100px;
		    padding-bottom: 50px;
		}
	}
	&.has-bg-image{
		padding: 0!important;
		background-attachment: fixed;		
		.inner{
			height: 400px;
		    @include media-breakpoint-up(lg){
		    	height: 600px;	    	
			}
			& > .image{
				visibility: hidden;
				display: none;
			}
		}
	}
}