.button, .btn{
    border: none;
    padding: 0;
    color: $primary;
    font-weight: 600;
    font-size: 15px;
    letter-spacing: 2px;
    position: relative;
    padding-bottom: 8px;
    &:before{
	    content: '';
	    height: 2px;
	    position: absolute;
	    background: $primary;
	    bottom: 0;
	    left: 0;
	    opacity: 0;
	    width: 0;
	    transition: all 0.3s ease-in-out;
    }
    &:hover{
    	color: $primary;
		&:before{
			width: 92px;
			opacity: 1;
			transition: all 0.3s ease-in-out;
		}
    }
}
.buttons-wrap{
	margin-top: 5px;
}