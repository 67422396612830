.accordion-wrap{
	.accordion-item{
		.accordion-title{
		    display: block;
		    border-bottom: 1px solid $secondary;
		    color: $primary;
		    font-size: 20px;
		    font-weight: bold;
		    padding: 27px 50px 27px 20px;
		    position: relative;
		    padding-left: 54px;
		    &:before{
		    	content: "\f067";
		    	font-family: FontAwesome;
		    	position: absolute;
		    	left: 16px;
				top: 50%;
	    	    transform: translateY(-50%);
    	        font-weight: normal;
    	        color: $secondary;
		    }
		    &:not(.collapsed){
		    	background-color: #f7f7f7;
		    	&:before{
	    		    content: "\f00d";
	    		    color: $secondary;
		    	}
		    	.has-bg-color &{
		    		background-color: #FFF;
		    	}
		    }
		}
		.accordion-content{
			background-color: #f7f7f7;
			& > div{
				padding: rem-calc(20 20 20 50);
			}
			.has-bg-color &{
				background: #FFF;
			}
		}
	}
}