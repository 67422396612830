.site-header{
	position: absolute;
	top: 0; left: 0; right: 0;
	z-index: 1000;
	.navbar-light{
		transition: all 0.5s ease-in-out;
		@include media-breakpoint-up(xl){
			padding-top: 50px;
			padding-left: 0;
			padding-right: 0;
		}
		
		& > .container{
			justify-content: space-between;
		}
		.navbar-brand{
			margin-right: 0;
		}
	}
	.navbar-toggler{
		color: #FFF;
		margin: 0;
		padding: 0;
		border: none;
    	position: relative;
	    // margin-top: -5px;
	    .menu-bar{	
	        width: 33px;
    		height: 4px;
		    background-color: #FFF;
		    margin: 7px 0;
		    transition: 0.4s;
		    display: block;
		}
		&[aria-expanded="true"]{
			.bar1 {
			    transform: rotate(-45deg) translate(-7px, 8px);
			}
			.bar3 {
			    transform: rotate(45deg) translate(-3px, -5px);
			}
			.bar2 {opacity: 0;}
		}
	}

	&.fixed{
		background: rgba(0, 0, 0, 0.8);
		.navbar-light{
			padding-top: 15px;
			padding-bottom: 15px;
			transition: all 0.7s ease-in-out;
		}
		@include media-breakpoint-up(xl){
			position: fixed;
			top: 0; left: 0; right: 0;
    		z-index: 2000;
		    box-shadow: 0 -5px 50px 10px rgba(0, 0, 0, 0.5);
		    .primary-nav li a{
				color: $primary;
		    }
		}
	}
}

.sticky-wrapper{
	display: none;
	// @include media-breakpoint-up(xl){
	// 	display: block;
	// }
}

.admin-bar{
	.site-header.fixed{
		top: 32px;
	}
}
body.home .site-header{
	.navbar-light .navbar-brand{ opacity: 0; }
	&.fixed{
		.navbar-light .navbar-brand{ opacity: 1; }
	}
}	
/** -----------------------------------------------------------
##	PAGE HEADER
--------------------------------------------------------------- */
.page-header {
	position: relative;
	background-color: $primary;
	color: $white;
	padding: rem-calc(60 0);
	overflow: hidden;
	text-align: center;
	z-index: 100;
	@include media-breakpoint-up(lg){
		height: 100vh;
		min-height: 350px;
	}
	.header-content_wrap{
	    position: absolute; 
	    top: 50%; left: 0; right: 0;
	    transform: translateY(-50%);
	    .site-title{
	    	font-size: 45px;
	    	text-transform: uppercase;
	    	font-weight: 600;
	    	position: relative;
	    	padding-bottom: 38px;
	    	&:before{
	    		content: '';
	    		position: absolute;
	    		width: 165px; height: 5px;
	    		bottom: 0;
	    		left: 50%;
	    		transform: translateX(-50%);
	    		background: #FFF;
	    	}
	    	& > span{
	    		font-family: 'Libre Baskerville', serif;
	    		font-style: italic;
	    		font-weight: 400;
	    		font-size: 30px;
	    		text-transform: initial;
	    	}
	    }
	}

	.page-header-hero{
		position: absolute;
		top: 0;
	    left: 0;
	    bottom: 0;
	    right: 0;
	    &:before{
	    	content: "";
	    	position: absolute;
	    	top: 0; left: 0; bottom: 0; right: 0;
    		opacity: 0.4;
	        background: linear-gradient(to bottom, rgba(71,13,53,1) 0%, rgba(255,255,255,0.06) 100%);
	    }
		.page-banner__main{
			width: 100%;
			height: 100%;
			@include obj-fit();
		}
	}
	.container{
		position: relative;
		z-index: 1000;
	}
}

/**
 * NAVIGATIONS
 */
.navbar-collapse{
	position: fixed;
    top: 0px; right: 0px; bottom: 0px;
    z-index: 10000;
    width: 100%;
    background: rgba(0, 0, 0, 0.8);
    transform: translateX(-100%);
    transition: all .2s ease-in-out;
    overflow-y: auto;
    height: 100%;
    &.collapsing{
    	height: 100%;
    }
    &.show{
	    transform: translateX(0px);
	    transition: all .2s ease-in-out;

	    & + #page-wrapper .site-header {
	    	visibility: hidden;
	    }
    }
	
}
.primary-nav {
    margin-top: 100px;
	text-align: right;
	.sub-menu {
		font-family: 'Libre Baskerville', serif;
		@extend %unstyle-list;
		display: none;
		padding-left: 2rem;
	}
	li {
		position: relative;
		margin-bottom: 20px;
		& > a {
			position: relative;
			text-transform: uppercase;
			padding: 10px 0;
			color: #FFF;
			font-weight: 700;
			font-size: 20px;
			&:before{
				content: '';
				position: absolute;
				bottom: 0; right: 0;
				height: 2px;
				width: 0;
				transition: all 0.2s ease-in-out;
				background: #FFF;
			}
		}

		&.show > .sub-menu {
			display: block;
			// transform: translateX(0);
		}
		&.show > a > .menu-tongle:before{
			content: "\f107" !important;
		}

		&.current-menu-ancestor > a,
		&.current-menu-item > a,
		&:hover > a{
			&:before{
				width: 100%;
				transition: all 0.2s ease-in-out;
			}
		}

		&.menu-item-has-children > a{
			position: relative;
		    padding-right: 25px;

			.menu-tongle{
				position: absolute;
			    width: 25px;
			    height: 100%;
			    right: 0;
			    top: 0;
			    &:before{
			    	content: "\f105";
			    	font-family: FontAwesome;
		    	    transform: translateY(-50%);
				    position: absolute;
				    top: 50%; right: 0;
			    }

			}
		}
		.sub-menu{
			padding-left: 0;
		    position: relative;
	        margin-bottom: 35px;
	        margin-top: 10px;
			li{
			    transform: skew(-15deg);
				& > a{
				    padding: 5px;
					text-transform: initial;
					color: #FFF;
					&:before{
						width: 0;
						left: 0px;
						right: auto;
					}
				}
				&.current-menu-item,
				&.current_page_item,
				&:hover{
					& > a{
						&:before{
							width: 100%;
						}
					}
				}
			}
		}
	}
	
}
.breadcrumbs {
	padding: 30px 0;
	text-align: center;
	font-size: 11px;
	font-weight: 600;
	color: $primary;
    font-style: italic;
    br{ display: none; }
	.breaker, a {
		text-transform: uppercase;
		font-style: normal;
		color: #000;
	}
	.breaker{
		margin-left: 10px;
		margin-right: 10px;
	}
}
#header-topbar{
	line-height: 1;
	.bs-info{
		a{
			color: #FFF;
		    width: 32px;
		    height: 22px;
		    display: inline-block;
			.fa{
				font-size: 20px;
				visibility: hidden;
			}
			&:hover{
				.fa{ transform: scale(1.2) };
			}
		}
		&.info-enquiries_email a{
		    background: url(../images/mail-icon.png) no-repeat center;
		}
		&.info-phone_number a{
		    background: url(../images/phone.png) no-repeat center;
		}
		& + .bs-info{
			margin-left: 20px;
		}
	}
}

.navbar-close{
	padding-right: 0;
	margin-left: auto;
	margin-top: 58px;
	z-index: 10000;
	display: flex;
	&:hover{ transform: scale(1.1); }
	.menu-x-sign{
	    display: block;
	    width: 33px;
	    height: 4px;
	    background: #FFF;
	    &.line-1{
			transform: rotate(45deg) translate(7px, 3px);
	    }
	    &.line-2{
		    transform: rotate(-45deg) translateY(4px);
	    }
	}
	.menu-text{
		margin-right: 11px;
	}
}
.menu-text{
	font-size: 20px;
	font-weight: 600;
	text-transform: uppercase;
	color: #FFF;
}

.navbar-show{
    display: flex;
    align-items: center;
    .menu-text{
    	margin-right: 28px;
    	font-weight: 700;
    }
}

@media (max-width: 640px){
	.site-header .navbar-light > .container{
		padding-left: 0;
		padding-right: 0;
	}
	.site-header .navbar-brand img{
		max-width: 200px;
	}
	#header-topbar .bs-info a .fa{
		font-size: 25px;
	}
	.site-header .navbar-toggler .menu-bar {
	    width: 25px;
	    height: 2px;	    
	    margin: 6px 0;
	}
	.primary-nav li > a{
		font-size: 18px;
	}
}
