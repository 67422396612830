/*--------------------------------------------------------------
## row-lead_ins
--------------------------------------------------------------*/
// row-lead_ins-grid
.row-lead_ins-listing, .row-lead_ins-grid{
	.lead-in-wrap{
		.lead-in-detail{
			@include media-breakpoint-down(md){
				margin-bottom: 15px;
				margin-top: 15px;
			}
			.leadin-overlay{
				.sub_title{
					font-size: 20px;
					font-weight: 500;
				}
			}
			.leadin-image{
			}
		}
	}
}

.row-lead_ins-listing{
	.lead-in-detail{
		overflow: hidden;
		margin: 0;
		.leadin-image{
			margin-bottom: 0;
			img{
				@include media-breakpoint-up(lg){
					position: absolute;
				    left: 15px;
				    right: 0;
				    top: 0;
				    bottom: 0;
				    width: calc(100% - 15px);
				    height: 100%;
				    @include obj-fit();
				}
			}
		}
		.leadin-overlay{
			padding-top: 50px;
			padding-bottom: 50px;
			@include media-breakpoint-up(lg){
				padding-left: 40px;
			}
		}
		&:nth-child(even){
			@include media-breakpoint-up(lg){
				.leadin-image{
					order: 2;
					img{
						left: 0;
					}
				}
				.leadin-overlay{
					padding-left: 15px;
					padding-right: 40px;
				}
			}
		}
		.buttons-wrap{
			padding-left: 4px;
		}
	}
} 

.row-lead_ins-grid{
	.additional__heading{ margin-bottom: 62px; }
	.leadin-overlay{
		.title{
			margin-bottom: 32px;
		}
	}
}