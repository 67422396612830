// Placeholder 
%font-icon {
	font: normal normal normal 14px/1 FontAwesome;
	font-size: inherit;
	display: inline-block;
}
%unstyle-list {
	list-style: none;
	padding: 0;
	margin: 0;
}

.menu {
	@extend %unstyle-list;
}

