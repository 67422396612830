/*--------------------------------------------------------------
# Media
--------------------------------------------------------------*/
.wp-smiley {
	border: none;
	margin-bottom: 0;
	margin-top: 0;
	padding: 0;
}


/* Make sure embeds and iframes fit their containers. */
embed,
iframe,
object {
	max-width: 100%;
	display: block;
}

.alignleft {
	display: inline;
	float: left;
	margin-right: 1.5em;
}

.alignright {
	display: inline;
	float: right;
	margin-left: 1.5em;
}

.aligncenter {
	clear: both;
	display: block;
	margin-left: auto;
	margin-right: auto;
}

a{
	img.alignright {
		float: right;
		margin: 0.313rem 0 1.25rem 1.25rem;
	}
	img.alignnone {
		margin: 0.313rem 1.25rem 1.25rem 0;
	}
	.alignleft {
		float: left;
		margin: 0.313rem 1.25rem 1.25rem 0;
	}
	img.aligncenter {
		display: block;
		margin-left: auto;
		margin-right: auto;
	}
}

/*--------------------------------------------------------------
## Captions
--------------------------------------------------------------*/

.wp-caption {
	background: #f1f1f1 none repeat scroll 0 0;
	border: 1px solid #f0f0f0;
	max-width: 96%;
	padding: 0.313rem 0.313rem 0;
	text-align: center;
	img[class*="wp-image-"] {
		border: 0 none;
		height: auto;
		margin: 0;
		max-width: 100%;
		padding: 0;
		width: auto;
	}
	.wp-caption-text {
		font-size: 0.688rem;
		line-height: 1.063rem;
		margin: 0;
		padding: 0.625rem;
		text-align: center;
	}
	&.alignnone {
		margin: 0.313rem 1.25rem 1.25rem 0;
	}
	&.alignleft {
		margin: 0.313rem 1.25rem 1.25rem 0;
	}
	&.alignright {
		margin: 0.313rem 0 1.25rem 1.25rem;
	}
}

/*--------------------------------------------------------------
## Galleries
--------------------------------------------------------------*/

.gallery {
	margin-bottom: 1.5em;
	@include make-row();
	&:before,
	&:after{
		display: none;
	}
	
	.gallery-item {
		@include make-col-ready();
		text-align: center;
		@include make-col(6);
	}

	@include media-breakpoint-up(lg) {
		&.gallery-columns-1 .gallery-item {
			@include make-col(12);
		}
		&.gallery-columns-2 .gallery-item {
			@include make-col(6);
		}
		&.gallery-columns-3 .gallery-item {
			@include make-col(4);
		}
		&.gallery-columns-4 .gallery-item {
			@include make-col(3);
		}
		&.gallery-columns-5 .gallery-item {
			flex: 0 0 20%;
			max-width: 20%;
		}
		&.gallery-columns-6 .gallery-item {
			@include make-col(2);
		}
		&.gallery-columns-7 .gallery-item {
			flex: 0 0 14.28571%;
			max-width: 14.28571%;
		}
		&.gallery-columns-8 .gallery-item {
			flex: 0 0 12.5%;
			max-width: 12.5%;
		}
		&.gallery-columns-9 .gallery-item {
			flex: 0 0 14.28571%;
			max-width: 11.11111%;
		}
	}

	.gallery-caption {
		display: block;
	}
}
