/*--------------------------------------------------------------
## row-call_to_action
--------------------------------------------------------------*/
.row-call_to_action{
	.caption-wrap{
	    text-align: center;
    	// padding: 30px 0;
		.caption__title{
		}
		.caption__sub-title, .caption{
			font-size: 14px;
		}
		.caption__sub-title{
		    margin-bottom: 17px;
		}
		.buttons-wrap{
		    margin-top: 30px;
			.btn{
			    color: #000;
			    border: 2px solid #000;
			    padding: 4px 12px;
			    letter-spacing: normal;
			    font-size: 16px;
			    font-weight: 600;
		        width: 279px;
				&:before{ display: none; }
				&:hover{
					background: #000;
					color: #FFF;
				}
			}
		}
	}
}