.banner-slider {
	background-color: $gray-200;
	visibility: hidden;
 	opacity: 0;	 
 	transition: opacity 1s;
 	&.slick-initialized {
		visibility: visible;
		opacity: 1;
	}
	.slick-slide {
		display: none;
		&:first-child {
	 		display: block;
	 		visibility: hidden;
	 	}
	}
	&.slick-initialized {
		.slick-slide {
			display: block;
			visibility: visible!important;
		}
	}
	.slick-dots{
		li{
			button:before{
				width: 20px;
    			height: 4px;
    			border-radius: 0;
    			background-color: #FFF;
			}
			&.slick-active button:before{
				background-color: $primary;
			}
		}
	}
	.banner-slide {
		&__item {
			position: relative;
			vertical-align: middle;
		}
		&__image {
			background: no-repeat center / cover;
			height: rem-calc(480);
			@include media-breakpoint-up(md) {
				height: 100vh;
				background-attachment: fixed;
			}
			&::before {
			    content: "";
			    position: absolute;
			    top: 0; left: 0; bottom: 0; right: 0;
			    background: rgba(0, 0, 0, 0.5);
			}
		}
		&__caption {
			position: absolute;
			left: 0;
			top: 50%;
			width: 100%;
			text-align: center;
			padding: rem-calc(40 0);
			transform: translate(0, -50%);
			@include media-breakpoint-up(xl) {
				margin-top: -50px;
			}
		}
		&-caption-outer {
			position: relative;
		}
		&-caption-box {
			color: $white;
		}
		&__title {
			font-size: rem-calc(32);
			font-weight: $font-weight-bold;
			@include media-breakpoint-up(sm) {
				font-size: rem-calc(48);
			}
		}
		&__description {
			font-size: 16px;
			font-weight: 400;
			// margin-top: rem-calc(20);
			@include media-breakpoint-up(sm) {
				font-size: rem-calc(20);
			}
			img{
				margin-bottom: 23px;
			}
		}
		&__buttons {
			margin-top: 10px;
			.btn {
				margin: 10px;	
			    border: 2px solid #FFF;
    			padding: 6px 12px;
    			background: transparent;
    			color: #FFF;
    			&:before{ display: none; }
				&:hover,
				&:focus {
					background: rgba(#000,0.8);
				}
			}
		}
	}
	.slick-prev,
	.slick-next {
		display: inline-block;
		width: 35px;
		height: 35px;
		background-color: transparent;
		position: absolute;
		top: 50%;
		font-size: 0;
		color: transparent;
		border: 0;
		padding: 0;
		outline: none;
		z-index: 10;
		transform: translate(0, -50%);
		@include media-breakpoint-up(md) {
			width: 55px;
			height: 55px;
		}
		&::before {
			font: normal normal normal 14px/1 FontAwesome;
			font-size: rem-calc(16);
			display: inline-block;
			width: 35px;
			height: 35px;
			color: $white;
			line-height: 35px;
			background-color: rgba(0,0,0,.55);
			border-radius: 3px;
			transition: background-color .3s;
			@include media-breakpoint-up(md) {
				width: 55px;
				height: 55px;
				line-height: 55px;
			}
		}
		&:hover {
			&::before {
				background-color: $primary;
			}
		}
	}
	.slick-prev {
		left: 10px;
		@include media-breakpoint-up(md) {
			left: 50px;
		}
		&::before {
			content: "\f104";
		}
	}
	.slick-next {
		right: 10px;
		@include media-breakpoint-up(md) {
			right: 50px;
		}
		&::before {
			content: "\f105";
		}
	}
}
