.row-default_content{
	.additional__heading{
		.heading{
			padding-bottom: 35px;
			& + p{
				line-height: 1.5625;
				margin-top: 55px;
			}
		}
	}
	.page__content{
		font-size: 18px;
	}
}

.row-page-default{
	.page__content{
		font-size: 18px;
	}
}
.page__content strong{ font-weight: 700; }