/*--------------------------------------------------------------
## row-logos
--------------------------------------------------------------*/
.row-logos-grid{
	.logos{
		.logo-item{
			margin-top: 15px;
			margin-bottom: 15px;
			height: 175px;
			display: flex;
		    justify-content: center;
	    	align-items: center;
			img{
				max-height: 100%; 
			}
		}
	}
}
.row-logos-carousel{
	.logo-item{
	    height: 135px;
	    display: inline-flex !important;
	    justify-content: center;
    	align-items: center;
		& > a img{
			max-height: 100%;
		}
	}
	.logos-carousel{
		.slick-dots{
			position: relative;
    		bottom: 0;
    		li{
    			button:before{ background: #ccc; }
    			&.slick-active button:before{ background: #000; }
    		}
		}
	}
}