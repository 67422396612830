/*--------------------------------------------------------------
## News Page
--------------------------------------------------------------*/
.news-item{
	margin-bottom: 30px;
	& > div{
		position: relative;
		height: 100%;
		border: 1px solid #f5f5f5;
	}
	.entry-thumbnail{
		&:before{
			padding-top: 70%;
		}
		.embed-responsive-item{
			@include obj-fit();
		}
	}
	.content-wrap{
		// position: relative;
		padding: 25px;
		.entry-header{
		    margin-bottom: 10px;
			.entry-title{
				color: $primary;
			    line-height: 1.24;
			    font-size: 20px;
			    margin-bottom: 10px;
			    font-weight: 300;
			}
			.entry-meta{
				color: #999;
			    font-size: 12px;
			}
		}
		.entry-content{
			padding-bottom: 60px;
		}
		.entry-footer{
			position: absolute;
			bottom: 25px;
			left: 25px;
		}
	}
}