//
// Utility functions
//
// Strips the unit from a single value entered.
// E.g. `strip-unit(30px)` returns `30`
@function strip-unit($value) {
    @return $value / ($value * 0 + 1);
}

// Converts numeric value(s) to rem units, based
// on the $font-size-root variable.
// E.g. `rem-calc(14px 16px 0)` returns `0.875rem 1rem 0`
@function convert-to-rem($value, $base-value:$font-size-root) {
    $value: strip-unit($value) / strip-unit($base-value) * 1rem;

    @if ($value==0rem) {
        $value: 0;
    }

    // Turn 0rem into 0
    @return $value;
}

@function rem-calc($values, $base-value:$font-size-root) {
    $max: length($values);

    @if $max==1 {
        @return convert-to-rem(nth($values, 1), $base-value);
    }

    $remValues: ();

    @for $i from 1 through $max {
        $remValues: append($remValues, convert-to-rem(nth($values, $i), $base-value));
    }

    @return $remValues;
}