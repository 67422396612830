/*--------------------------------------------------------------
## row-testimonials
--------------------------------------------------------------*/
.row-testimonials{
	.testimonial-wrap{
		.testimonial-item{
			& > div{
				padding: 0 50px;
				@include media-breakpoint-up(lg){
					padding: 0px 170px;
				}
			}
			&:before{
				left: 50%;
				transform: translateX(-50%);
			}
			.name{
				&:before{
					left: 50%;
					transform: translateX(-50%);
				}
			}
		}
	}
}

.testimonial-wrap{
	.testimonial-item{
		position: relative;
		margin-bottom: 30px;
		padding-top: 65px;
		padding-left: 35px;
		padding-right: 35px;
		&:before{
			position: absolute;
			top: 0;
			left: 35px;
			content: '\f10d ';
			font-family: fontawesome;
			color: #aaa7a7;
			font-size: 30px;
			line-height: 1;
		}
		.name{
			font-family: $headings-font-family;
        	font-weight: $headings-font-weight;
        	color: $headings-color;
        	font-size: 18px;
        	position: relative;
        	padding-top: 24px;
    		margin-top: 25px;
        	&:before{
				content: "";
				display: block;
				border-top: 1px solid;
				width: 44px;
				position: absolute;
				left: 0;
				top: 0;
        	}
		}
	}
}




