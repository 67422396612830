// Use this file to overwrite the basic Bootstrap variables and add your own variables
// To overwrite a Bootstrap variable you don´t have to touch the Bootstrap folder. 
// Just copy a variable from node_modules/bootstrap/scss/_variables.scss, paste it here and edit the value.

// Fonts

$font-size-root: 16px;

$font-family-base : 'Montserrat', sans-serif;
$font-size-base   : 1rem;
$font-weight-base : 300;
$line-height-base : 1.5625;

$h2-font-size: 2.3125rem; //37px
$h3-font-size: 1.5625rem; //25px

$h1-font-size: 2.5rem; //40px
$h4-font-size: 1.375rem;
$h5-font-size: 1.25rem;
$h6-font-size: 1.125rem;

//$headings-font-family: 'Montserrat', sans-serif;
$headings-font-weight: 700;
// $headings-line-height: 1;
// $headings-color: inherit;

// Colors
$primary    : #470d35;
$secondary  : #40403c;
$body-color : #000;

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-rounded: false;
$enable-caret: false;

// Body
//
// Settings for the `<body>` element.



// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

// $grid-breakpoints: (
// 	xs  : 0,
// 	sm  : 576px,
// 	smd : 768px,
// 	md  : 992px,
// 	lg  : 1200px,
// 	xl  : 1600px
// );

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
 	xl: 1170px
);

// Fonts
//
// Font, line-height, and color for body text, headings, and more.

//$headings-color: $gray-500;

// Links
//
// Style anchor elements.

$link-hover-decoration: none;


// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

$input-btn-focus-box-shadow: none;

// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.


