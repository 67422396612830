@mixin admin-sticky-fix($offset:0) {
    $narrow-offset: 46px;
    $wide-offset: 32px;

    @if $offset !=0 and type-of($offset)=='number' {
        $narrow-offset: $narrow-offset + $offset;
        $wide-offset: $wide-offset + $offset;
    }

    .admin-bar & {
        top: $narrow-offset;

        @media screen and (min-width:783px) {
            top: $wide-offset;
        }

    }

}

@mixin obj-fit() {
    object-fit: cover;
    font-family: 'object-fit:cover;';
}

/* Utility for prefixing mixins -------------------------------------------- */

@mixin prefixer($property, $value, $prefixes: webkit moz) {
  $valid: false;
  @if type-of($property)=="string" {
    $valid: true;
  }
  @if type-of($prefixes)=="list" {
    $valid: true;
  }
  @if type-of($prefixes)=="string" {
    $valid: true;
  }
  @if $valid==true {
    @each $prefix in $prefixes {
      #{"-" + $prefix + "-" + $property}: $value;
    }
    #{$property}: $value;
  }
  @else {
    @warn "Invalid arguments supplied";
  }
}

/* Renders fully prefixed box-sizing --------------------------------------- */
@mixin box-sizing($box:border-box) {
    $valid: true;

    @if type-of($box) !="string" {
        @warn "box:# {
            $box
        }

        is not a string";
        $valid: false;
    }

    @if $valid==true {
        @include prefixer(box-sizing, $box, webkit moz);
    }

}

/* Renders fully prefixed transform ---------------------------------------- */
@mixin transform($property:none) {
    $valid: false;

    @if type-of($property)=="string" {
        $valid: true;
    }

    @if type-of($property)=="list" {
        $valid: true;
    }

    @if $valid==true {
        @include prefixer(transform, $property, webkit moz ms);
    }

    @else {
        @warn "property:# {
            $property
        }

        is not a valid argument";
    }

}

/* Renders fully prefixed transistion -------------------------------------- */
@mixin transition($property:0.15s) {
    $valid: false;

    @if type-of($property)=="string" {
        $valid: true;
    }

    @if type-of($property)=="list" {
        $valid: true;
    }

    @if type-of($property)=="number" {
        $valid: true;
    }

    @if $valid==true {
        @include prefixer(transition, $property, webkit moz ms);
    }

    @else {
        @warn "property:# {
            $property
        }

        is not a valid argument";
    }

}

/* Renders fully prefixed border radius ---------------------------------------- */
@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    border-radius: $radius;
    background-clip: padding-box;
}

@mixin border-top-radius($radius) {
    -webkit-border-top-right-radius: $radius;
    border-top-right-radius: $radius;
    -webkit-border-top-left-radius: $radius;
    border-top-left-radius: $radius;
    background-clip: padding-box;
}

@mixin border-right-radius($radius) {
    -webkit-border-bottom-right-radius: $radius;
    border-bottom-right-radius: $radius;
    -webkit-border-top-right-radius: $radius;
    border-top-right-radius: $radius;
    background-clip: padding-box;
}

@mixin border-bottom-radius($radius) {
    -webkit-border-bottom-right-radius: $radius;
    border-bottom-right-radius: $radius;
    -webkit-border-bottom-left-radius: $radius;
    border-bottom-left-radius: $radius;
    background-clip: padding-box;
}

@mixin border-left-radius($radius) {
    -webkit-border-bottom-left-radius: $radius;
    border-bottom-left-radius: $radius;
    -webkit-border-top-left-radius: $radius;
    border-top-left-radius: $radius;
    background-clip: padding-box;
}

/* Removes all styles from an ordered/unordered list ----------------------- */
@mixin reset-list-styles($float) {
    list-style-type: none;
    padding: 0;
    margin: 0;

    li {
        float: $float;
    }

}

// Placeholder
@mixin placeholder {
    ::-webkit-input-placeholder {
        @content
    }

    :-moz-placeholder {
        @content
    }

    ::-moz-placeholder {
        @content
    }

    :-ms-input-placeholder {
        @content
    }

}

//Clearfix
@mixin clearfix() {
    &:before, &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }

}

// Cross Browser opacity
@mixin opacity($opacity) {
    opacity: $opacity;
    $opacity-ie: $opacity * 100;
    filter: alpha(opacity=$opacity-ie);
}

//Flex box
@mixin flexbox() {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

@mixin objectfit() {
    object-fit: cover;
    width: 100%;
    height: 100%;
}