/** --------------------------------------------------------------
## FOOTER
------------------------------------------------------------------ */
.footer-widgets {
    padding: 100px 0 50px;
    background: $secondary;
    color: #FFF;
    font-size: 14px;
    font-weight: 400;
    border-bottom: 2px solid #FFF;
    .widget{ margin-bottom: 15px; }
    .widget-title{
    	font-weight: 300;
    }
    [class*=col-]{
        margin-bottom: 30px;
    }
    .widget_search{
        margin-top: -10px;
        margin-bottom: 10px;
    }
    .widget_nav_menu{
        .sub-menu{
            display: none;
        }
        .menu-item{
            &:not(:last-child){
                margin-bottom: 15px;
            }
            &.current-menu-item{
                a{
                    &:before{
                        opacity: 1;
                        width: 50px;
                        transition: all 0.3s ease-in-out;
                    }
                }
            }
        }
    }
    a{
    	position: relative;
        color: currentColor;
        padding-bottom: 2px;
        text-transform: uppercase;
        &:before{
        	content: '';
        	position: absolute;
        	bottom: 0;
        	left: 0;
        	height: 1px;
        	background: #FFF;
        	opacity: 0;
        	width: 0;
        	transition: all 0.3s ease-in-out;
        }
        &:hover:before{
        	opacity: 1;
        	width: 50px;
			transition: all 0.3s ease-in-out;
        }
    }
    .widget-socials{
        .bs-socials{
            a{
                background: #40403c;
                color: #FFF;
            }
        }
    }
    .widget_media_image{
        & > a img{
            @include media-breakpoint-down(sm){
                width: 100%;
            }
        }
    }
}
.footer-info {
    font-size: 14px;
    font-weight: 400;
    padding: 19px 0;
    background: $secondary;
    color: #FFF;
    @include media-breakpoint-down(md) {
        text-align: center;
    }
    .footer__nav {
        display: inline-block;
        .menu {
            li {
                display: inline-block;
                line-height: 1;
                &:not(:last-child){
                    padding-right: 10px;
                    margin-right: 10px;
                }                
            }
        }
    }
    .titan-logo {
        width: 16px;
        height: 16px;
        display: inline-block;
        fill: currentColor;
        vertical-align: -2px;
    }
    .footer__created-by {
    	a{
    		color: #FFF;
    		font-weight: 700;
            border-bottom: 1px solid transparent;
            display: inline-block;
            &:hover{
                border-bottom-color: #FFF;
            }
    	}
        @include media-breakpoint-down(md) {
            margin-top: rem-calc(6);
        }
    }
}

/** --------------------------------------------------------------
## BACK TO TOP
------------------------------------------------------------------ */
#back-to-top {
    text-align: left;
    position: relative;
    color: #000;
    cursor: pointer;
    border: 0;
    border-radius: 2px;
    text-decoration: none;
    opacity: 0;
    z-index: 9999;
    transition: opacity 0.2s ease-out; 
    font-size: 15px;
    font-weight: 600;
    line-height: 1.2;
    transition: all 0.2s ease-in-out;
    // &:hover {
    //     background: #0071bb;
    // }
    &:before{
        content: '';
        width: 19px;
        height: 100%;
        position: absolute;
        left: -30px;
        background: url('../images/back-to-top.png') no-repeat top center;
        background-size: contain;
    }
    &.show {
        opacity: 1;
    }
    &:hover{
        transition: all 0.2s ease-in-out;
        transform: scale(1.1);
    }
}