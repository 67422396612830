/**
 * Custom variables Bootstrap
 * https://getbootstrap.com/docs/4.0/getting-started/theming/#variable-defaults
 */
@import "global/_custom";


// Third Party
@import "font-awesome";
@import "bootstrap";
@import "slick.scss";
@import "jquery.fancybox.min";
@import 'animate';

// Global
@import "global/functions";
@import "global/mixins";
@import "global/base";

// Components
@import "components/media";
@import "components/slick-theme";

// Modules
@import "modules/header";
@import "modules/footer";
@import "modules/banner";
@import "modules/sidebar";
@import "modules/content";
//@import "modules/woocommerce"; // Disable this if Woocommerce is not active

@import "modules/partials/accordion";
@import "modules/partials/call-to-action";
@import "modules/partials/downloads";
@import "modules/partials/featured-content";
@import "modules/partials/featured-image";
@import "modules/partials/featured-video";
@import "modules/partials/gallery";
@import "modules/partials/google-map";
@import "modules/partials/gravity-form";
@import "modules/partials/latest-posts";
@import "modules/partials/lead-ins";
@import "modules/partials/logos";
@import "modules/partials/testimonials";
@import "modules/partials/default-content";
@import "modules/partials/buttons";
@import "modules/partials/content-repeater";
@import "modules/partials/search-form";

