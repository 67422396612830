.row-featured_video{
	&:before{
		content: "";
		position: absolute;
	    top: 0;
	    left: 0;
	    bottom: 0;
	    right: 0;
	    background: rgba(0, 0, 0, 0.25);
	}
}
.featured-video{
	text-align: center;
	.featured-video-link{
		font-size: 100px;
		color: #FFF;
		&:hover{
			color: $primary;
		}
	}
}