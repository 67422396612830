/*--------------------------------------------------------------
## row-gallery
--------------------------------------------------------------*/
.row-gallery{
	.gallery-wrap{
		.gallery-item{
			margin-top: 15px;
			margin-bottom: 15px;
			& > a{
				&:after{
					content: '\f002';
				    font-family: FontAwesome;
				    position: absolute;
				    top: 0;
				    left: 0;
				    right: 0;
				    bottom: 0;
				    display: flex;
				    justify-content: center;
				    align-items: center;
				    background: rgba(0, 0, 0, 0.6);
				    font-size: 30px;
				    opacity: 0;
				    transition: all 0.3s ease-in-out;
				    color: #FFF;
				}
				&:hover{
					transition: all 0.3s ease-in-out;
					&:after{
						opacity: 1;
					}
				}
			}
		}
	}
}
