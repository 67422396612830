.row-featured_content{
	.page__thumbnail{
		padding-bottom: rem-calc(60);
		@include media-breakpoint-up(lg){
			padding-bottom: 0;
		}
		&.large-size{
			& > div{
				@include media-breakpoint-up(lg){
				    right: 0;
				    height: 100%;
				    width: 50vw;
				    position: absolute;
				    overflow: hidden; 
				    img{
			    	    width: 100%;
					    height: 100%;
				    }
				}
			}

			img{
		    	@include obj-fit();
		    }

			&.order-lg-2{
				& > div{
				    left: 0;
				    right: auto;
				}
				& + .page__content{
					@include media-breakpoint-up(lg){
						padding-right: rem-calc(50);
						padding-left: rem-calc(15);
					}	
				}
			}
			& + .page__content{
				@include media-breakpoint-up(lg){
					padding-left: rem-calc(50);
				}
			}
		}
	}	
	.page__content{
		padding-bottom: 1rem;
		@include media-breakpoint-up(lg){
			padding-bottom: 0;
			&.more-space-top{
				padding-top: rem-calc(85);
			}
			&.more-space-bottom{
				padding-bottom: rem-calc(85);
			}
		}
	}	
}