.search-form{
	.search-submit{
	    background: transparent;
	    color: #FFF;
	    padding: 0;
	    border-bottom: 1px solid transparent;
	    font-size: 16px;
	    &:before{ display:none; }

	}
	.search-field{
	    font-size: 14px;
	    background: transparent;
	    color: #FFF;
	    border: none;
	    border-bottom: 1px solid transparent;
	    &::placeholder{
	    	color: inherit;
	    	font-weight: inherit;
	    	font-size: inherit;
	    	text-transform: uppercase;
	    }
	}
}
.search-field:focus,
.search-field:focus + .search-submit{
	border-bottom-color: #FFF;
}