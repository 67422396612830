.widget {
	margin-bottom: rem-calc(30);
	&:last-child{
		margin-bottom: 0;
	}
}
.widget-title {
	font-weight: $headings-font-weight;
	font-family: $headings-font-family;
	line-height: $headings-line-height;
	color: $headings-color;
	margin-bottom: rem-calc(20);
}