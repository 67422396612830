/*--------------------------------------------------------------
## row-google_map
--------------------------------------------------------------*/
.acf-map {
	width: 100%;
	height: 400px;

	/* fixes potential theme css conflict */
	img {
	   max-width: inherit !important;
	}
}

.row-google_map{
	.acf-map{
		.map__info-wrapper{
			.section-heading{
			    font-size: 16px;
			}
		}
	}
	.embed-code{
		iframe{
			width: 100%;
		}
	}
}