/*------------------------------------*
	BASE
*------------------------------------*/
body {
	overflow-x: hidden;
	min-height: 700px;
}

u:last-child,
p:last-child{
	margin-bottom: 0;
}

img{
	max-width: 100%;
	height: auto;
}

.highlight pre{
    margin-top: 0;
    margin-bottom: 24px;
    padding: 15px 30px;
    font-size: 1em;
    background: #f7f7f7;
    border-radius: 2px;
    overflow-x: auto;
}
.deep-link{ display: none; }
// Remove outline when focus item
:focus {
	outline: none !important;
}

/*------------------------------------*
	EFFECT
*------------------------------------*/
[data-os-animation]:not([data-os-animation="none"]){
  	opacity: 0;
  	&.animated{
	    opacity: 1;
	}
}

.effect > span,
.effect > a{
    position: relative;
    display: block;
    max-width: 100%;
    &:before{
    	position: absolute;
	    content: "";
	    top: 0;
	    left: 0;
	    width: 0;
	    height: 0;
	    margin: auto;
	    background-color: rgba(255,255,255,0.1);
	    transition: all 0.3s ease-out 0s;
    }
    &:after{
    	content: "";
	    position: absolute;
	    right: 0;
	    bottom: 0;
	    width: 0;
	    height: 0;
	    background-color: rgba(255,255,255,0.1);
	    transition: all 0.3s ease-out 0s;
    }
    &:hover:before,
	&:hover:after {
		width: 100%;
	    height: 100%;    
	}
}

/** -----------------------------------------------------------
##	PAGINATION
--------------------------------------------------------------- */
.pagination {
	margin-bottom: 0;
}
.titan-pagination {
	padding-top: rem-calc(50);
	.page-link {
		min-width: 41px;
		text-align: center;
		background-color: $gray-100;
		border: 0;
		font-size: rem-calc(16);
		padding: rem-calc(8 10);
		margin: 0 3px;
		&:hover,
		&.current {
			background-color: $black;
			color: $white;
		}
	}
	.infinite &{
		display: none;
	}
}

.infinite{
	.load-more {
	    position: relative;
	    width: 100%;
	    flex: 0 0 100%;
	    max-width: 100%;
	    height: 70px;
	    background: url(../images/loader.gif) no-repeat center;
	    background-size: contain;
	}
}

/*--------------------------------------------------------------
# Content
--------------------------------------------------------------*/

.widget-contact{
    .business-info{
        .info{
            display: flex;
            &:not(:last-child), .license-item:not(:last-child){
                padding-bottom: 20px;
                margin-bottom: 20px;
                border-bottom: 1px solid #e1e1e1;
            }
            .info__value{
            }
            .info__icon{
                width: 40px;
            }
            .info__title{
                width: 120px;
                font-weight: 500;
            }
            .bs-socials{
                font-size: 11px;
                a{
                    width: 25px; height: 25px; line-height: 25px;
                    &:not(:last-child){ margin-right: 10px; }
                }
            }
        }
    }
}

.widget-socials{
    .bs-socials{
        & > a{
            &:not(:last-child){ margin-right: 6px; }
        }
        @include media-breakpoint-down(sm){
            white-space: normal;
            margin-top: -5px;
            margin-bottom: -5px;
            & > a{
                margin-top: 5px;
                margin-bottom: 5px;
            }
        }
    }
}

.opening_hours-item{
    display: flex;
     &:not(:last-child){
        margin-bottom: 10px;
    }
    .date-time{
        width: 140px;
    }
    .description{}
}

.bs-socials{
	white-space: nowrap;
	a{
		width: 28px;
		height: 28px;
		text-align: center;
	    background: #FFF;
    	color: $secondary;
	    display: inline-flex;
	    justify-content: center;
	    align-items: center;
    	&:before{ display:none; }
		&:hover,
		&:focus{
			transform: scale(1.2);
		}
		.fa{
		    font-weight: 700;
    		font-size: 18px;
		}
	}
}
.additional__heading .heading,
.has-underline{
	position: relative;
	padding-bottom: 22px;
	&:before{
	    content: '';
	    position: absolute;
	    bottom: 0;
	    width: 108px;
	    height: 4px;
	    background: #470d35;
	    left: 50%;
	    transform: translateX(-50%);
	}
}
/*--------------------------------------------------------------
# Additional Content
--------------------------------------------------------------*/
.additional{	
	position: relative;
	padding: rem-calc(60 0);
	&__heading{
	    margin-bottom: rem-calc(30);
	    .heading{
	    	padding-bottom: 47px;
			line-height: 1;
			&:before{
				width: 202px;
	    		height: 5px;
			}
	    	& > span{
	    		font-family: 'Libre Baskerville', serif;
	    		font-style: italic;
	    		font-weight: 400;
	    		font-size: 25px;
	    	}
			margin-bottom: 0;
			& + p{
				line-height: 1.25;
				margin-top: 2rem;
			}
	    }
	    @include media-breakpoint-up(lg){
	    	padding-left: 16%;
			padding-right: 16%;
		}
	    @include media-breakpoint-up(xl){
			padding-left: 20%;
			padding-right: 20%;
		}
	}

	&__footer{
		margin-top: rem-calc(30);	
	}

	&.group-open{
		.additional__layout_wrap .additional__layout_wrap.container{
			padding-left: 0;
			padding-right: 0;
		}
	}


	.layout__full-stretched{
		.additional__content{
			@include make-container();
			@include make-container-max-widths();
		}
	}

	.additional__content{
		position: relative;
		z-index: 100;
	}	
}

/*--------------------------------------------------------------
## Socials
--------------------------------------------------------------*/


/*--------------------------------------------------------------
## Default Page & Loop
--------------------------------------------------------------*/

.df_layout{
	margin-bottom: 30px;
	.entry-thumbnail{
		.inner{
		    width: 100%;
			@include media-breakpoint-up(lg){
				position: relative;
				height: 100%;
				&:before{ display: none; }
				img{
					position: absolute;
					top: 0; left: 0;
					width: 100%; height: 100%;
				}
			}
		}
	}
	.entry-content{
		.inner{
			padding: 30px;
		    background: #f1f1f1;
			@include media-breakpoint-up(lg){
		        padding: 50px 30px;
			    // background: none;
			}
		}
	}
}

.elfsight-widget-instagram-feed.elfsight-widget{
	.eapps-instagram-feed-content{
		@include media-breakpoint-up(lg){
			margin: 0 -30px;		
		}
	}
	.eapps-instagram-feed-title{
		font-family: 'Montserrat', sans-serif;
		font-size: 37px !important;
		font-weight: 700 !important;
	}
}

ol{
	counter-reset: item;
	padding-left: 0;
	li {
	    display: block;
	    margin-top: 5px;
	    margin-bottom: 10px;
	    &:before {
		    content: counters(item, ".") ". ";
		    counter-increment: item;
        	display: inline-block;
    		margin-right: 15px;
		}
		ol{
			padding-left: 1.5rem;
		}
	}
}


@media (max-width: 830px){
	.ui-datepicker{
		width: calc(100% - 30px) !important;
	}
}
@media (max-width: 767px){
	.page-header .header-content_wrap .site-title{
		font-size: 32px;
	}
	.caption__title h2,
	.elfsight-widget-instagram-feed.elfsight-widget .eapps-instagram-feed-title,
	.additional__heading .heading{
		font-size: 30px;
	}
	.row-lead_ins-grid .leadin-overlay .title{
		font-size: 20px;
	}

	.site-header .navbar-light .navbar-brand {	    
	    max-width: calc(100% - 100px);
	    img{
	        width: 250px;
    		max-width: 100%;
		}
	}
}
