.slick-prev,
.slick-next {
	position: absolute;
	top: 50%;
	display: inline-block;
	background-color: transparent;
	font-size: 0;
	color: transparent;
	border: 0;
	cursor: pointer;
	padding: 0;
	z-index: 1;
	transform: translateY(-50%);
	&:focus {
		outline: none;
	}
	&::before {
		display: inline-block;
		font: normal normal normal 14px/1 FontAwesome;
		font-size: rem-calc(18);
		color: #333;
	}
}
.slick-prev {
	left: 0;
	&::before {
		content: "\f053";
	}
}
.slick-next {
	right: 0;
	&::before {
		content: "\f054";
	}
}
.slick-arrow{
	&:hover:before{ color: $primary; }
	&.slick-next{ right: -30px }
	&.slick-prev{ left: -30px }
}
.slick-dots {
	list-style: none;
	margin: 0;
	padding: 0;
	position: absolute;
	bottom: 18px;
	width: 100%;
	text-align: center;
	li {
		display: inline-block;
		cursor: pointer;
		button {
			display: inline-block;
			background-color: transparent;
			font-size: 0;
			color: transparent;
			border: 0;
			cursor: pointer;
			padding: 4px;
			&::before {
				content: '';
				width: 8px;
				height: 8px;
				background-color: rgba($white, .8);
				display: inline-block;
				border-radius: 50%;
				transition: background-color .3s;
			}
			&:focus {
				outline: none;
			}
		}
		&.slick-active {
			button {
				&::before {
					background-color: rgba($black, .8);
				}
			}
		}
	}
}