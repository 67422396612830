/*
 * BUTTON
 */

.btn,
.button{
	border-color: $primary;
}

.button{
	@extend .btn;
}

.buttons-wrap{
    margin-left: -5px;
    margin-right: -5px;
	.btn, .button{
		margin: 5px;
	}
}



// Gravity Form
.gform_wrapper {
	form {
		max-width: 100%;
    	margin: 0 auto;
	}
    li {
        list-style: none;
    }
	ul.gform_fields {
        list-style: none;
		margin: 0;
        padding: 0;
        li.gfield {
            margin-bottom: rem-calc(30);
            &.field-icon{
				.ginput_container{
					position: relative;
					&:before{
						font-family: FontAwesome;
						color: $primary;
						position: absolute; 
						top: 13px;
						left: 15px;
					}
					.form-control:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]){
						padding-left: 35px;
					}
				}
				&.icon-name .ginput_container:before{
				    content: "\f007";
				}
				&.icon-mail .ginput_container:before{
				    content: "\f0e0";
				}
				&.icon-phone .ginput_container:before{
				    content: "\f095";
				}
				&.icon-time .ginput_container:before{
				    content: "\f017";
				}
				&.icon-message .ginput_container:before{
			        content: "\f040";
				}
			}
        }
        .gf_left_half,
        .gf_right_half {
          display: inline-block;
          width: 100%;
          vertical-align: top;
          @include media-breakpoint-up(sm) {
            width: 50%;
          }
        }
        .gf_left_half {
            @include media-breakpoint-up(sm) {
                padding-right: rem-calc(15);
            }
        }
        .gf_right_half {
            @include media-breakpoint-up(sm) {
                padding-left: rem-calc(15);
            }
        }
        .ginput_container_name {
            @include media-breakpoint-up(sm) {
                display: flex;
                justify-content: space-between;
            }
            .name_first,
            .name_last {
                display: block;
                width: 100%;
                @include media-breakpoint-up(sm) {
                    width: calc(50% - 9px);
                }
            }
            .name_first {
                margin-bottom: rem-calc(18);
                @include media-breakpoint-up(sm) {
                    margin-right: rem-calc(18);
                    margin-bottom: 0;
                }
            }
        }
	}
    ul.gfield_checkbox, 
    ul.gfield_radio {
        padding: 0;
        margin: 0;
    }
	
	.gf_invisible,
	.gfield_visibility_hidden {
	    visibility: hidden;
	    position: absolute;
	    left: -9999px
	}
	.hidden_label .gfield_label,
	label.hidden_sub_label,
	label.screen-reader-text {
		clip: rect(1px,1px,1px,1px);
	    position: absolute !important;
	    height: 1px;
	    width: 1px;
	    overflow: hidden;
	}
    .form-control {
    	&::placeholder {
	        color: inherit;
	    }
	    &:focus {
	        border-color: $primary;
	    }
    }
	input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]),
	select, 
	textarea {}
    input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]),
    select {}
    input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]), 
    textarea {}
	textarea.form-control {
		resize: none;
	}

    .gfield_checkbox {
        li {
            padding: rem-calc(6 0);
        }
        input[type=checkbox] {
            width: 22px;
            height: 22px;
            display: inline-block;
            vertical-align: middle;
            margin-top: -2px;
            margin-right: 8px;
        }
        label {
            margin: 0;
        }
    }
	li.gfield_error {
		input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]),
		textarea,
		select {
			border-color: $red;
		}
	}

	.validation_error {
        display: none;
	    color: $red;
    	margin-bottom: 1.25rem;
	}
	li.gfield.gfield_error.gfield_contains_required div.gfield_description {
		display: none;
		color: $red;
	}
		
	.gfield_select{
		background: url(../images/select.jpg) no-repeat right;
		-webkit-appearance: none;
		appearance: none;
		&::-ms-expand {
		    display: none;
		}
	}
	.gfield_radio{
		.radio{
			&:not(:last-child){ margin-right: 31px; }
			label{
				position: relative;
				line-height: 37px;
				margin-bottom: 0;
				.radio-custom{
					border: 2px solid #000;
					width: 43px;
					height: 37px;
				    line-height: 34px;
    				text-align: center;
    				display: inline-block;
    				margin-right: 14px;
    				cursor: pointer;
					&:before{
					    content: "";
					    width: 11px;
					    height: 11px;
					    background: #000;
					    display: inline-block;
					    border-radius: 50%;
					    visibility: hidden;
					}
				}
			}	
			input[type="radio"]{
			    opacity: 0;
			    position: absolute;
			    z-index: -1;
			    left: -99999px;
				&:checked + label .radio-custom:before{
					visibility: visible;
				}
				&:focus + label .radio-custom{
					border-color: #000;
				}
			}
		}		
	}
	label.gfield_label{
		color: #6c757d;
		font-family: Montserrat, sans-serif;
		font-size: 14px;
	}
	label.gfield_label, ul.gfield_radio{ padding-left: 10px; }
    .gform_heading{
		margin-bottom: 30px;
    	.gform_title{
    		font-family: $headings-font-family;
    		font-weight: $headings-font-weight;
    		line-height: $headings-line-height;
    		color: $headings-color;
    	}
    	.gform_description{
    		display: block;
    	}
    }
    .gform_footer {}
	.form-flex-col{
		& > .gform_body > .gform_fields,
		& > .gform_fields{			
			@include make-row();
			& > .gfield{
				margin-bottom: 0;
			}
			& > .gfield:not([class*="col-"]) {
				@include make-col-ready();
			}
		}
		margin-bottom: 0!important;
		@include media-breakpoint-up(sm){
			margin-bottom: 30px!important;
		}
		@media (max-width: 575px){
			.gfield[class*="col-"]{
				margin-bottom: 30px;
			}
		}
	}
}

.gform_validation_container, 
.gform_wrapper .gform_validation_container, 
body .gform_wrapper .gform_body ul.gform_fields li.gfield.gform_validation_container, 
body .gform_wrapper li.gform_validation_container, 
body .gform_wrapper ul.gform_fields li.gfield.gform_validation_container {
	display: none !important;
    position: absolute !important;
    left: -9000px;
}

/** -----------------------------------------------------
## Halves (2 Columns)
--------------------------------------------------------- */
.gform_horizontal {
    .gform_body {
        .gfield {
            @include media-breakpoint-up(sm) {
                &.gfield_half_left,
                &.gfield_half_right {
                    display: inline-block;
                    width: 50%;
                    vertical-align: top;
                }
                &.gfield_half_left {
                    padding-right: rem-calc(18);
                }
                &.gfield_half_right {
                    padding-left: rem-calc(18);
                }
            }
        }
    }
    .gform_footer {
        text-align: center;
        margin-top: rem-calc(50);
    }
}

/** -----------------------------------------------------
## Halves (3 Columns)
--------------------------------------------------------- */

/** -----------------------------------------------------
## Datepicker
--------------------------------------------------------- */
.ui-datepicker {
    background-color: #fff;
    padding: 1rem;
    box-shadow: 0px 5px 15px 0px rgba(0,0,0,0.19);
    display: none;
    font-size: 14px;
    width: 800px;
    max-width: 100%;
    .ui-datepicker-header {
    	position: relative;
    }
    &-title {
	    text-align: center;
	    line-height: 1.875rem;
	    margin: .5rem 2.5rem;
	    select {
	        &:not([size]):not([multiple]) {
	            height: auto;
	            padding: 5px 32px 5px 10px;
	            line-height: 1.1;
	            width: calc(50% - 5px);
			    padding-right: 15px;
			    font-size: 14px;
	        }
	        & + select {
	            margin-left: 10px;
	        }
	    }
	}
	.ui-icon {
	    text-indent: -9999px
	}
	.ui-datepicker-prev,
	.ui-datepicker-next {
		position: absolute;
	    top: 0;
	    width: 1.875rem;
	    height: 1.875rem;
	    overflow: hidden;
	    line-height: 1.875rem;
	    text-align: center;
	    cursor: pointer;
	    &::before {
	    	@extend %font-icon;
	    }
	    span {
	    	display: block;
		    position: absolute;
		    overflow: hidden;
	    }
	}
	.ui-datepicker-prev {
		left: 0;
		&::before {
			content: "\f0d9";
		}
	}
	.ui-datepicker-next {
		right: 0;
		&::before {
			content: "\f0da";
		}
	}
	table {
		border-collapse: collapse;
    	margin-bottom: 0;
	    width: 100%;
		thead {
			border: 0;
	    	background: none;
			th {
			    font-size: .75rem;
			    text-align: center;
			    padding: rem-calc(4 0);
			}
		}
		tbody {
			td {
				border: 1px solid #f1f1f1;
    			padding: 0;
    			font-size: 14px;
    			color: #1b1b1b;
    			background: none;
			    text-align: center;
    			&.ui-datepicker-unselectable.ui-state-disabled {
    				background-color: #fafafa;
    			}
    			a, span {				    
					display: inline-block;
				    line-height: 2.5rem;
				    background: none;
				    width: 100%;
				    color: #1b1b1b;
    			}
			}
		}
	}
	@at-root .ui-datepicker-calendar {
		.ui-state-default {
			background-color: $white;
		}
		.ui-state-hover {
			background-color: #e8e8e8;
		}
		.ui-state-active {
			background-color: $primary;
    		color: $white;
		}
		.ui-datepicker-unselectable {
			.ui-state-default {
			    background: #fdfdfd;
			    color: #d2d2d2;		        
			}
		}
	}
}
body div#ui-datepicker-div[style] {
    z-index: 9999
}


// custom
.row-gravity_form{
	font-weight: 600;
	.gfield_checkbox{
		.checkbox{
			display: inline-block;
			&:not(:last-child){
				margin-right: 30px;
			}
		}	
	}
	.form-control, .datepicker{
		border: 2px solid #000; 
		font-size: 14px;
		font-weight: 300;
		height: 37px;
	    font-weight: 600;
		&::placeholder{
		}
	}
	.gfield_select{ max-height: 37px; }
	.gfield_label{ font-weight: 600; }
	.ginput_container_date{
		position: relative;
		.datepicker{
			width: 100%;
			padding: 6px 12px;
		}
		.ui-datepicker-trigger{
		    position: absolute;
		    top: 50%;
		    transform: translateY(-50%);
		    right: 20px;
		}
	}
	.textarea{ height: 197px; }
	.gform_footer{
		.gform_button{
			color: #000;
			font-size: 14px;
			padding: 6px 12px;
		    border: 2px solid #000;
		    width: 167px;
	        letter-spacing: normal;
		    background: transparent;
			&:before{ display: none; }
			&:hover{
				background: #000;
				color: #FFF;
			}
		}
	}
	.ginput_container_radio{
		.gfield_radio{
			display: flex;
		}
	}
}
